import { FilterMatchMode } from '@capturum/ui/api';
import { ListOptions } from '@capturum/api';
import { InfoTableFilterConfig } from '@shared/modules/info-table-filters/info-table-filter-config.model';
import { Entity } from './../enums/entity.enum';

export class FilterUtil {
  public static setApiOptions(apiOptions: ListOptions, filters: any, filterConfigs: InfoTableFilterConfig[]): any {
    return {
      ...apiOptions,
      filters: Object.keys(filters)
        // Adjust form value for API filters
        .map((key) => {
          const operator = filterConfigs?.find((config) => {
            return config.field === key;
          });

          return {
            field: key,
            value: filters[key],
            operator: operator ? operator?.matchMode || FilterMatchMode.LIKE : FilterMatchMode.LIKE,
          };
        })
        // Exclude empty filters
        .filter((filter) => {
          return Array.isArray(filter?.value) ? filter?.value?.length : !!filter.value;
        })
        // Add percentage for LIKE operator
        .map((filter) => {
          if (filter.operator === FilterMatchMode.LIKE) {
            filter.value = `%${filter.value}%`;
          }

          return filter;
        }),
    };
  }

  public static getEntity(url: string): Entity {
    const parsedUrl = url.split('/').reverse();
    const entity = Object.keys(Entity).find((key) => {
      return parsedUrl[0] === Entity[key];
    }) as Entity;

    return entity || (url as Entity);
  }
}
